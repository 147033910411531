import React, { useEffect, useState } from 'react'; // Added useState and useEffect
import { Input, Select, Table, Space, Pagination, Spin } from 'antd'; // Ensure Pagination and Spin are imported

const { Search } = Input;
const { Option } = Select;

const UserManagement = () => {
  const [users, setUsers] = useState([]); // 状态用于存储用户数据
  const [teams, setTeams] = useState([]); // 状态用于存储团队数据
  const [teamPage, setTeamPage] = useState(1); // 状态用于团队下拉列表的分页
  const [totalTeams, setTotalTeams] = useState(0); // 状态用于总团队数
  const [userPage, setUserPage] = useState(1); // 状态用于用户下拉列表的分页
  const [totalUsers, setTotalUsers] = useState(0); // 状态用于总用户数
  const [searchName, setSearchName] = useState(''); // 状态用于存储搜索关键字
  const [selectedTeam, setSelectedTeam] = useState(null); // 状态用于存储选中的团队
  const [teamSearchName, setTeamSearchName] = useState(''); // 状态用于存储团队搜索关键字
  const [loading, setLoading] = useState(false); // 新增状态用于管理加载状态

  const fetchTeams = async (page, searchName = '') => { // 修改获取团队数据的函数以支持搜索
    try {
      const response = await fetch(`https://test-wine.wineyun.com/go-vinotes/vinotes/v1/admin/teamList?page=${page}&limit=5&team_name=${searchName}`);
      const data = await response.json();
      setTeams(data.data.list);
      setTotalTeams(data.data.total);
    } catch (error) {
      console.error('获取团队时错:', error);
    }
  };

  const fetchUsers = async (page, userName = '', teamId = '') => {
    setLoading(true); // 开始加载
    try {
      const url = new URL('https://test-wine.wineyun.com/go-vinotes/vinotes/v1/admin/userList');
      url.searchParams.append('page', page);
      url.searchParams.append('limit', '10');
      if (userName) url.searchParams.append('user_name', userName);
      if (teamId) url.searchParams.append('team_id', teamId);

      const response = await fetch(url);
      const data = await response.json();
      setUsers(data.data.list);
      setTotalUsers(data.data.total);
    } catch (error) {
      console.error('获取用户列表时错误:', error);
    } finally {
      setLoading(false); // 结束加载
    }
  };

  useEffect(() => { // 新增 useEffect 以获取团队数据
    fetchTeams(teamPage, teamSearchName); // 传递团队搜索关键字
    fetchUsers(userPage, searchName, selectedTeam || '');
  }, [teamPage, userPage, searchName, selectedTeam, teamSearchName]);

  const columns = [
    { title: '用户UID', dataIndex: 'uid', key: 'uid' },
    { title: '用户昵称', dataIndex: 'user_name', key: 'user_name' },
    { title: '所在团队', dataIndex: 'team_name', key: 'team_name' },
    { title: '个人笔记数', dataIndex: 'note_num', key: 'note_num' },
    { title: '团队笔记数', dataIndex: 'team_note_num', key: 'team_note_num' },
  ];

  return (
    <div>
      <h2>用户管理</h2>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="用户昵称"
          style={{ width: 200 }}
          onSearch={(value) => {
            setSearchName(value);
            setUserPage(1);
          }}
        />
        <Select 
          placeholder="所在团队" 
          style={{ width: 200 }} 
          showSearch 
          onSearch={(value) => {
            setTeamSearchName(value);
            setTeamPage(1);
            fetchTeams(1, value);
          }}
          onFocus={() => fetchTeams(teamPage, teamSearchName)}
          onChange={(value) => {
            setSelectedTeam(value);
            setUserPage(1);
            fetchUsers(1, searchName, value || '');
          }}
          dropdownRender={menu => (
            <div>
              {menu}
              <div style={{ padding: '8px', textAlign: 'center' }}>
                <Pagination 
                  current={teamPage} 
                  total={totalTeams} 
                  pageSize={5} 
                  onChange={(page) => {
                    setTeamPage(page);
                    fetchTeams(page, teamSearchName);
                  }}
                  showSizeChanger={false}
                />
                <a
                  style={{ display: 'block', marginTop: '8px', color: '#1890ff', cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedTeam(null);
                    setUserPage(1);
                    setUsers([]); // 清空用户列表
                    setTeamSearchName(''); // 重置团队搜索关键字
                    setTeamPage(1); // 重置团队分页
                    fetchUsers(1, searchName, ''); // 重新获取用户列表
                  }}
                >
                  清空选择
                </a>
              </div>
            </div>
          )}
          filterOption={false} // 禁用本地筛选，使用服务端搜索
          value={selectedTeam}
        >
          {teams.map(team => (
            <Option key={team.team_id} value={team.team_id}>{team.name}</Option>
          ))}
        </Select>
      </Space>
      {loading ? (
        <Spin size="large" style={{ margin: 'auto', display: 'block' }} />
      ) : (
        <Table 
          columns={columns} 
          dataSource={users} 
          rowKey="uid"
          pagination={{
            current: userPage,
            total: totalUsers,
            onChange: setUserPage,
            showSizeChanger: false,
          }}
        />
      )}
    </div>
  );
};

export default UserManagement;