import React from 'react';
import { Layout, Menu, Avatar } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  UserOutlined,
  FileTextOutlined,
  TeamOutlined,
  BarChartOutlined,
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

const AppLayout = () => {
  const location = useLocation();

  const menuItems = [
    {
      key: '/user-management',
      icon: <UserOutlined />,
      label: <Link to="/user-management">用户管理</Link>,
    },
    {
      key: '/note-management',
      icon: <FileTextOutlined />,
      label: <Link to="/note-management">笔记管理</Link>,
    },
    {
      key: '/team-management',
      icon: <TeamOutlined />,
      label: <Link to="/team-management">团队管理</Link>,
    },
    {
      key: '/statistics',
      icon: <BarChartOutlined />,
      label: <Link to="/statistics">统计数据</Link>,
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h1 style={{ color: 'white', margin: 0 }}>Winenotes管理系统</h1>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar icon={<UserOutlined />} />
          <span style={{ color: 'white', marginLeft: '10px' }}>用户昵称</span>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;