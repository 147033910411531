import React, { useState, useEffect } from 'react';
import { Input, Table, Typography, Spin } from 'antd';

const { Search } = Input;
const { Text } = Typography;

const columns = [
  { title: '团队ID', dataIndex: 'team_id', key: 'team_id' },
  { title: '团队名称', dataIndex: 'team_name', key: 'team_name' },
  { title: '团队介绍', dataIndex: 'team_about', key: 'team_about' },
  { title: '创建时间', dataIndex: 'create_time', key: 'create_time' },
  { title: '创建人', dataIndex: 'create_name', key: 'create_name' },
  { title: '管理员', dataIndex: 'superintendent', key: 'superintendent' },
  { title: '笔记总数', dataIndex: 'note_num', key: 'note_num' },
  { title: '分享总数', dataIndex: 'share_num', key: 'share_num' },
];

const TeamManagement = () => {
  const [noteCount, setNoteCount] = useState(0);
  const [teamCount, setTeamCount] = useState(0);
  const [teams, setTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false); // New state for loading

  useEffect(() => {
    // Fetch summary data
    fetch('https://test-wine.wineyun.com/go-vinotes/vinotes/v1/admin/summary')
      .then(response => response.json())
      .then(data => {
        const { note_num, team_num } = data.data;
        setNoteCount(note_num);
        setTeamCount(team_num);
      })
      .catch(error => {
        console.error('There was an error fetching the summary data!', error);
      });

    // Fetch team data with search term
    const fetchTeams = async () => {
      setLoading(true); // Start loading
      try {
        const response = await fetch(`https://test-wine.wineyun.com/go-vinotes/vinotes/v1/admin/team?team_name=${searchTerm}`);
        const data = await response.json();
        const teamList = data.data.list.map(team => ({
          key: team.team_id,
          ...team
        }));
        setTeams(teamList);
      } catch (error) {
        console.error('There was an error fetching the team data!', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchTeams();
  }, [searchTerm]);

  return (
    <div>
      <h2>团队管理</h2>
      <div style={{ marginBottom: 16 }}>
        <Search 
          placeholder="团队名称" 
          style={{ width: 200 }} 
          onSearch={value => setSearchTerm(value)} 
          enterButton 
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Text strong style={{ marginRight: 16 }}>笔记总数：{noteCount}</Text>
        <Text strong>团队总数：{teamCount}</Text>
      </div>
      {loading ? <Spin size="large" /> : <Table columns={columns} dataSource={teams} />}
    </div>
  );
};

export default TeamManagement;