import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppLayout from './components/Layout';
import UserManagement from './pages/UserManagement';
import NoteManagement from './pages/NoteManagement';
import TeamManagement from './pages/TeamManagement';
import Statistics from './pages/Statistics';
import NoteDetail from './pages/NoteDetail'; // 确保导入了 NoteDetail

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="user-management" element={<UserManagement />} />
          <Route path="note-management" element={<NoteManagement />} />
          <Route path="team-management" element={<TeamManagement />} />
          <Route path="statistics" element={<Statistics />} />
        </Route>
        <Route path="note-detail/:noteId" element={<NoteDetail />} /> // 移出 AppLayout
      </Routes>
    </Router>
  );
}

export default App;