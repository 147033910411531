import React, { useEffect, useState } from 'react';
import { Input, Select, Table, Space, Pagination, Spin } from 'antd';

const { Search } = Input;
const { Option } = Select;

const NoteManagement = () => {
  const [teams, setTeams] = useState([]); // 状态用于存储团队数据
  const [currentPage, setCurrentPage] = useState(1); // 状态用于分页
  const [totalTeams, setTotalTeams] = useState(0); // 状态用于总团队数
  const [selectedTeam, setSelectedTeam] = useState(null); // 状态用于存储选中的团队
  const [noteContent, setNoteContent] = useState(''); // 状态用于存储笔记内容搜索
  const [notes, setNotes] = useState([]); // 状态用于存储笔记数据
  const [currentTeam, setCurrentTeam] = useState(null); // 状态用于存储当前选中的团队
  const [teamPage, setTeamPage] = useState(1); // 状态用于团队下拉列表的分页
  const [totalTeamPages, setTotalTeamPages] = useState(0); // 状态用于团队总页数
  const [loading, setLoading] = useState(false); // 新增loading状态

  const handleTeamFocus = () => {
    setTeamPage(1); // 每次点击时重置为第一页
    fetchTeams(1); // 获取第一页的团队数据
  };

  // 使用 useEffect 团队据
  useEffect(() => {
    fetchTeams(1); // 组挂载时调用，获取第一页数据
  }, []); // 只在组件挂载时调用

  const fetchTeams = async (page) => {
    try {
      const response = await fetch(`https://test-wine.wineyun.com/go-vinotes/vinotes/v1/admin/teamList?page=${page}&limit=5`);
      const data = await response.json();
      setTeams(data.data.list);
      setTotalTeams(data.data.total);
      setTotalTeamPages(Math.ceil(data.data.total / 5)); // 计算总页数
    } catch (error) {
      console.error('获取团队时出错:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page); // 更新当前页
    fetchNotes(page); // 确保在页码变化时调用获取笔记
  };

  const handleTeamChange = (value, option) => {
    setSelectedTeam(value); // 更新选中的团队ID
    setCurrentTeam(value); // 更新当前选中的团队
    fetchNotes(1); // 在选择团队后立即获取笔记列表
  };

  const handleTeamPageChange = (page) => {
    setTeamPage(page); // 更新团队下拉列表的当前页
    fetchTeams(page); // 直接使用新的页码调用接口获取新的团队数据
  };

  const handleClearSelection = () => {
    setSelectedTeam(null); // 清除选的团队
    setCurrentTeam(null); // 清除当前选中的团队
    setTeams([]); // 清空团队列表
    fetchTeams(1); // 重新获取团队数据
  };

  const columns = [
    { title: '笔记ID', dataIndex: 'note_id', key: 'note_id', width: 100 }, // 设置宽度以防换行
    { 
      title: '笔记标题', 
      dataIndex: 'title', 
      key: 'title',
      render: (text, record) => (
        <a href="#" onClick={() => window.open(`/note-detail/${record.uuid}`, '_blank')}>{text}</a> // 使用uuid进行跳转
      )
    },
    { title: '笔记摘要', dataIndex: 'abstract', key: 'abstract' },
    { title: '创建人', dataIndex: 'create_name', key: 'create_name', width: 100 }, // 设置宽度以防换行
    { title: '创建时间', dataIndex: 'create_time', key: 'create_time' },
    { 
      title: '笔记类型', 
      dataIndex: 'note_type', 
      key: 'note_type',
      width: 100, // 设置宽度以防换行
      render: (type) => type === 1 ? '个人' : '团队'
    },
  ];

  // Modify the fetchNotes function to work without a currentTeam
  const fetchNotes = async (page) => {
    setLoading(true); // 开始加载时设置loading为true
    try {
      const url = new URL('https://test-wine.wineyun.com/go-vinotes/vinotes/v1/admin/noteList');
      url.searchParams.append('page', page);
      url.searchParams.append('limit', '10');
      if (currentTeam) {
        url.searchParams.append('team_id', currentTeam);
      }
      if (noteContent) {
        url.searchParams.append('content', noteContent);
      }

      const response = await fetch(url);
      const data = await response.json();
      setNotes(data.data.list);
      setTotalTeams(data.data.total);
    } catch (error) {
      console.error('获取笔记时出错:', error);
    } finally {
      setLoading(false); // 加载完成后设置loading为false
    }
  };

  // Modify the useEffect hook to fetch notes on component mount
  useEffect(() => {
    fetchNotes(currentPage);
  }, [currentPage, currentTeam, noteContent]);

  const handleSearch = (value) => {
    setNoteContent(value);
    setCurrentPage(1);
    fetchNotes(1);
  };

  return (
    <div>
      <h2>笔记管理</h2>
      <Space style={{ marginBottom: 16 }}>
        <Search 
          placeholder="笔记内容" 
          style={{ width: 200 }} 
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          onSearch={handleSearch}
        />
        <Select 
          placeholder="所在团队" 
          style={{ width: 200 }} 
          showSearch 
          value={selectedTeam}
          onChange={handleTeamChange}
          optionFilterProp="children"
          onSearch={handleSearch} // 添加搜索处理函数
          onFocus={handleTeamFocus} // 添加焦点处理函数
          dropdownRender={menu => (
            <div>
              {menu}
              {/* 使用 Pagination 组替代清除选择按钮 */}
              <div style={{ padding: '8px', textAlign: 'center' }}>
                <Pagination 
                  current={teamPage} 
                  total={totalTeams} 
                  pageSize={5} 
                  onChange={handleTeamPageChange} 
                  showSizeChanger={false} // 隐藏页面大小选择器
                />
                <a
                  style={{ display: 'block', marginTop: '8px', color: '#1890ff', cursor: 'pointer' }}
                  onClick={handleClearSelection} // 添加清空选择的处理函
                >
                  清空选择
                </a>
              </div>
            </div>
          )}
        >
          {teams.map(team => (
            <Option key={team.team_id} value={team.team_id}>{team.name}</Option>
          ))}
        </Select>
      </Space>
      <Spin spinning={loading}> {/* 根据loading状态显示或隐藏Spin组件 */}
        <Table columns={columns} dataSource={notes} pagination={{
          current: currentPage,
          total: totalTeams,
          onChange: handlePageChange,
        }} />
      </Spin>
    </div>
  );
};

export default NoteManagement;