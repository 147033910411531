import React, { useEffect, useState } from 'react';
import { Table, Spin, DatePicker } from 'antd'; // Import DatePicker component

const { RangePicker } = DatePicker;

const Statistics = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState(null); // State for date range

  useEffect(() => {
    const fetchData = async (startDate, endDate) => {
      let url = 'https://test-wine.wineyun.com/go-vinotes/vinotes/v1/common/statistical_summary';
      if (startDate && endDate) {
        const startTimestamp = startDate.unix(); // Convert to Unix timestamp
        const endTimestamp = endDate.unix(); // Convert to Unix timestamp
        url += `?start_time=${startTimestamp}&end_time=${endTimestamp}`; // Update parameter names
      }
      const response = await fetch(url);
      const result = await response.json();
      if (result.error_code === 0) {
        setData([
          { key: 1, item: '已创建团队', total: result.data.team_sum, yesterday: result.data.team_ct },
          { key: 2, item: '用户', total: result.data.user_sum, yesterday: result.data.user_ct },
          { key: 3, item: '笔记', total: result.data.note_sum, yesterday: result.data.note_ct },
          { key: 4, item: '分享', total: result.data.share_sum, yesterday: result.data.share_ct },
          { key: 5, item: '询价', total: result.data.inquiry_sum, yesterday: result.data.inquiry_ct },
          { key: 6, item: '快团团成功', total: result.data.ktt_group_ok_sum, yesterday: result.data.ktt_group_ok_ct },
          { key: 7, item: '快团团失败', total: result.data.ktt_group_fail_sum, yesterday: result.data.ktt_group_fail_ct },
        ]);
      }
      setLoading(false);
    };

    if (dates) {
      fetchData(dates[0], dates[1]); // Pass moment objects directly
    } else {
      fetchData(); // Fetch without date range if no dates are selected
    }
  }, [dates]);

  const getColumnProps = (dataIndex) => ({
    onCell: (record) => ({
      style: {
        color: record.yesterday > 0 ? 'red' : 'inherit',
      },
    }),
  });

  const columns = [
    { title: '统计项', dataIndex: 'item', key: 'item', ...getColumnProps('item') },
    { title: '总数', dataIndex: 'total', key: 'total', ...getColumnProps('total') },
    { title: '昨日新增', dataIndex: 'yesterday', key: 'yesterday', ...getColumnProps('yesterday') },
  ];

  return (
    <div>
      <h2>数据统计</h2>
      <div style={{ marginBottom: 20 }}>
        <RangePicker 
          onChange={(dates) => {
            setLoading(true); // Set loading to true when dates change
            setDates(dates);
          }} 
        />
      </div>
      {loading ? <Spin style={{ marginTop: 20 }} /> : (
        <Table 
          columns={columns} 
          dataSource={data} 
          pagination={false}
        />
      )}
    </div>
  );
};

export default Statistics;