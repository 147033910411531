import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function renderBlock(block) {
    // 根据 block 的类型渲染内容
    switch (block.type) {
        case 1: // 文本类型
            const textContent = JSON.parse(block.content);
            return (
                <div style={{ margin: '20px 0', padding: '15px', backgroundColor: 'transparent' }}> {/* Removed border and box shadow */}
                    {textContent.ops.map((op, index) => (
                        <span key={index} style={{ 
                            color: op.attributes?.color || '#333', 
                            fontSize: op.attributes?.fontSize || '16px', // Increased font size
                            lineHeight: '1.5' // Improved line height for readability
                        }}>
                            {op.insert}
                        </span>
                    ))}
                </div>
            );
        case 2: // 图片类型
            const imageContent = JSON.parse(block.content);
            return (
                <div style={{ margin: '10px 0' }}>
                    <img src={`https://images.wineyun.com${imageContent.path}`} alt="" style={{ width: '100%', borderRadius: '8px' }} />
                </div>
            );
        case 3: // 多图类型
            const multiImageContent = JSON.parse(block.content);
            return (
                <div style={{ margin: '10px 0' }}>
                    {multiImageContent.list.map((img, index) => (
                        <img key={index} src={`https://images.wineyun.com${img.path}`} alt="" style={{ width: '100%', borderRadius: '8px', marginBottom: '10px' }} />
                    ))}
                </div>
            );
        case 4: // 视频类型
            const videoContent = JSON.parse(block.content);
            return (
                <div style={{ margin: '10px 0' }}>
                    <video controls style={{ width: '100%', borderRadius: '8px' }}>
                        <source src={`https://images.wineyun.com${videoContent.path}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        case 5: // 酒款参数类型
            const wineContent = JSON.parse(block.content);
            return (
                <div style={{ margin: '20px 0', padding: '15px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                    <h3 style={{ color: '#333' }}>{wineContent.name} ({wineContent.nameEn})</h3>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr>
                                <td style={{ border: '1px solid #ccc', padding: '8px', width: '150px' }}>产地</td> {/* Fixed width */}
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>{wineContent.place}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ccc', padding: '8px', width: '150px' }}>酒精度</td> {/* Fixed width */}
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>{wineContent.alcohol}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ccc', padding: '8px', width: '150px' }}>酒庄</td> {/* Fixed width */}
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>{wineContent.chateau}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ccc', padding: '8px', width: '150px' }}>国家</td> {/* Fixed width */}
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>{wineContent.country}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ccc', padding: '8px', width: '150px' }}>葡萄品种</td> {/* Fixed width */}
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>{wineContent.grapeVariety}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        default:
            return null; // 处理未知类型
    }
}

function NoteDetail() {
    const { noteId } = useParams(); // 获取 noteId 参数
    const [noteData, setNoteData] = useState(null);

    useEffect(() => {
        const fetchNoteDetail = async () => {
            try {
                const response = await fetch(`https://test-wine.wineyun.com/go-vinotes/vinotes/v1/note/detail?uuid=${noteId}`);
                const data = await response.json();
                setNoteData(data.data);
            } catch (error) {
                console.error("Error fetching note details:", error);
            }
        };

        fetchNoteDetail();
    }, [noteId]); // 依赖 noteId

    if (!noteData) return <div>正在加载...</div>;

    return (
        <div style={{ padding: '0px', backgroundColor: 'white', borderRadius: '0px', maxWidth: '70%', margin: '0 auto' }}>
            <h1 style={{ color: '#333', fontSize: '2.5em', marginBottom: '20px' }}>{noteData.title}</h1>
            <img src={`https://images.wineyun.com${noteData.head_img}`} alt={noteData.title} style={{ width: '100%', borderRadius: '8px', marginBottom: '20px' }} />
            <p style={{ fontStyle: 'italic', color: '#666', marginBottom: '20px' }}>创建于: {noteData.create_time} | 更新于: {noteData.update_time}</p>
            {noteData.blocks.map((block, index) => (
                <div key={index}>
                    {renderBlock(block)} {/* 确保 renderBlock 在这里可用 */}
                </div>
            ))}
        </div>
    );
}

export default NoteDetail;